export const CSS_PREFIX = 'asyncapi';
export const CONTENT_TYPES_SITE =
  'https://www.iana.org/assignments/media-types';
export const COLLAPSE_ALL_TEXT = 'Collapse All';
export const EXPAND_ALL_TEXT = 'Expand All';

export const VALIDATION_ERRORS_TYPE =
  'https://github.com/asyncapi/parser-js/validation-errors';
export const SERVERS = 'Servers';

export const ONE_OF_PAYLOADS_TEXT = 'One of those payloads:';
export const ANY_OF_PAYLOADS_TEXT = 'Any of those payloads:';
export const GENERATED_BADGE_TEXT = 'generated';
export const ONE_OF_FOLLOWING_MESSAGES_PUBLISH_TEXT =
  'You can send one of the following messages:';
export const ONE_OF_FOLLOWING_MESSAGES_PUBLISH_SINGLE_TEXT =
  'You can send the following message:';
export const ONE_OF_FOLLOWING_MESSAGES_SUBSCRIBE_TEXT =
  'You can subscribe to one of the following messages:';
export const ONE_OF_FOLLOWING_MESSAGES_SUBSCRIBE_SINGLE_TEXT =
  'You can subscribe to the following message:';

export const CONTACT_TEXT = 'Contact';
export const NAM_TEXTE = 'Name';
export const URL_TEXT = 'Url';
export const EMAIL_TEXT = 'Email';
export const LICENSE_TEXT = 'License';
export const TERMS_OF_SERVICE_TEXT = 'Terms of service';
export const URL_SUPPORT_TEXT = 'Support';
export const EMAIL_SUPPORT_TEXT = 'Email support';
export const EXTERAL_DOCUMENTATION_TEXT = 'External Docs';
export const LOCATION_TEXT = 'Location';
export const TYPE_TEXT = 'Type';
export const SPECIFICATION_TEXT = 'Specification';

export const DEPRECATED_TEXT = 'Deprecated';
export const PUBLISH_TEXT = 'Publish';
export const SUBSCRIBE_TEXT = 'Subscribe';
export const REQUIRED_TEXT = 'Required';
export const GENERATED_TEXT = 'Generated';

export const SERVERS_TEXT = 'Servers';
export const OPERATIONS_TEXT = 'Operations';
export const MESSAGES_TEXT = 'Messages';
export const SCHEMAS_TEXT = 'Schemas';

export const CHANNELS_TEXT = 'Channels';
export const PARAMETERS_TEXT = 'Parameters';
export const HEADERS_TEXT = 'Headers';
export const MESSAGE_HEADERS_TEXT = 'Message Headers';
export const HEADERS_EXAMPLE_TEXT = 'Example of headers';
export const TAGS_TEXT = 'Tags';
export const PAYLOAD_TEXT = 'Payload';
export const MESSAGE_PAYLOAD_TEXT = 'Message Payload';
export const PAYLOAD_EXAMPLE_TEXT = 'Example of payload';
export const SCHEMA_EXAMPLE_TEXT = 'Example';

export const NONE_TEXT = 'None';
export const ANY_TEXT = 'Any';
export const ERROR_TEXT = 'Error';
export const EXPAND_ERROR_BUTTON_TEXT = 'Expand';
export const COLLAPSE_ERROR_BUTTON_TEXT = 'Collapse';

export const SECURITY_TEXT = 'Security';

export const URL_VARIABLES_TEXT = 'URL Variables';
